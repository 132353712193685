// extracted by mini-css-extract-plugin
export var banner = "ReviewCentreGroup--banner--NnYMH";
export var banner__sub = "ReviewCentreGroup--banner__sub--hLRTA";
export var banner__sub__reviewsLink = "ReviewCentreGroup--banner__sub__reviewsLink--TiYi2";
export var banner__sub__score = "ReviewCentreGroup--banner__sub__score--tcgLV";
export var banner__sub__stars = "ReviewCentreGroup--banner__sub__stars--h60B+";
export var banner__sub__totalReviews = "ReviewCentreGroup--banner__sub__totalReviews--nH0T-";
export var banner__title = "ReviewCentreGroup--banner__title--Tt60P";
export var reviews = "ReviewCentreGroup--reviews--XwrHO";
export var reviews__group = "ReviewCentreGroup--reviews__group--MAZrQ";
export var reviews__group__block = "ReviewCentreGroup--reviews__group__block--0U2RB";
export var reviews__group__link = "ReviewCentreGroup--reviews__group__link--Xo78B";
export var reviews__logoContainer = "ReviewCentreGroup--reviews__logoContainer--jV0Yr";
export var tkMyriadProSemiCondensed = "ReviewCentreGroup--tk-myriad-pro-semi-condensed----aHg";