import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import Markdown from 'components/common/markdown/Markdown';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import reviewGeneratorHook from 'hooks/review-generator-intro/review-generator-intro.hook';

import {
  intro as introClass,
  intro__content,
  intro__content__title,
  intro__content__subTitle,
  intro__content__body,
} from './ReviewCentreIntro.scss';

export default (): JSX.Element => {
  const intro = reviewGeneratorHook().node;

  const heroImage = useStaticQuery(graphql`
    query {
      directusFile(title: { eq: "Review Centre Hero" }) {
        id
        title
        data {
          full_url
        }
        width
        height
        localFile {
          childImageSharp {
            fluid(maxHeight: 400, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  `);

  return (
    <>
      <BackgroundImage
        fluid={heroImage.directusFile.localFile.childImageSharp.fluid}
        alt={intro.image_alt}
        className={introClass}
        Tag="div"
      >
        <Wrapper>
          <div className={intro__content}>
            <h1 className={intro__content__title}>
              <Markdown source={intro.title} />
            </h1>
            <h2 className={intro__content__subTitle}>
              <Markdown source={intro.subtitle} />
            </h2>
            <p className={intro__content__body}>
              <Markdown source={intro.body} />
            </p>
          </div>
        </Wrapper>
      </BackgroundImage>
    </>
  );
};
