import { graphql, useStaticQuery } from 'gatsby';
import { AllDirectusReviewGeneratorIntro, ReviewGeneratorIntroEdges } from './review-generator-intro.interface';

export default (): ReviewGeneratorIntroEdges => {
  const { allDirectusReviewCentreIntro } = useStaticQuery<AllDirectusReviewGeneratorIntro>(graphql`
    query {
      allDirectusReviewCentreIntro {
        edges {
          node {
            directusId
            image {
              data {
                full_url
              }
              width
              height
              localFile {
                childImageSharp {
                  fluid {
                    ...GatsbyImageSharpFluid_withWebp_noBase64
                  }
                }
              }
            }
            image_alt
            title
            subtitle
            body
            bubble_title
            bubble_subtitle
          }
        }
      }
    }
  `);

  return allDirectusReviewCentreIntro.edges[0];
};
