import { graphql, useStaticQuery } from 'gatsby';
import { initials } from 'utils/initials';
import { AllDirectusReviewCentreReview } from './review-centre-review.interface';

export default (): any => {
  const { allDirectusReviewCentreReview, allTrustpilotReview } = useStaticQuery<AllDirectusReviewCentreReview>(graphql`
    query {
      allDirectusReviewCentreReview {
        edges {
          node {
            directusId
            review_company
            user_name
            score
            date_of_creation
            body
          }
        }
      }
      allTrustpilotReview(limit: 5) {
        edges {
          node {
            id
            reviewer
            stars
            title
            body
            domain
            review_date
            updated_at
            created_at
          }
        }
      }
    }
  `);

  const reviewsByReviewCompany = (companyName: string) =>
    allDirectusReviewCentreReview.edges
      .filter(({ node }) => node.review_company === companyName)
      .map(({ node }) => ({
        directusId: node.directusId,
        reviewCompany: node.review_company,
        userName: node.user_name,
        userInitials: initials(node.user_name),
        score: node.score,
        dateOfCreation: node.date_of_creation,
        body: node.body,
      }));

  const trustpilot = allTrustpilotReview.edges.map(({ node }, i) => ({
    directusId: node.id,
    sort: i + 1,
    reviewCompany: 'Trustpilot',
    userName: node.reviewer,
    userInitials: initials(node.reviewer),
    score: node.stars,
    dateOfCreation: node.created_at,
    title: node.title,
    body: node.body,
    review_date: node.review_date,
  }));

  return {
    trustpilot,
    freeindex: reviewsByReviewCompany('Freeindex'),
    yell: reviewsByReviewCompany('Yell'),
    google: reviewsByReviewCompany('Google'),
    facebook: reviewsByReviewCompany('Facebook'),
  };
};
