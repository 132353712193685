// extracted by mini-css-extract-plugin
export var MENU_OPEN = "ReviewCentre--MENU_OPEN--oiK4P";
export var tabList = "ReviewCentre--tabList--KWjQV";
export var tabs = "ReviewCentre--tabs--8eJnR";
export var tabs__list = "ReviewCentre--tabs__list--ImP6S";
export var tabs__list__active = "ReviewCentre--tabs__list__active--IZsSw";
export var tabs__list__item = "ReviewCentre--tabs__list__item--k+0BK";
export var tabs__nav = "ReviewCentre--tabs__nav--OT4L2";
export var tabs__nav__active = "ReviewCentre--tabs__nav__active--SwnAA";
export var tabs__nav__menuButton = "ReviewCentre--tabs__nav__menuButton--5-dhl";
export var tkMyriadProSemiCondensed = "ReviewCentre--tk-myriad-pro-semi-condensed--L64BO";