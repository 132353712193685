// extracted by mini-css-extract-plugin
export var intro = "ReviewCentreIntro--intro--563dD";
export var intro__content = "ReviewCentreIntro--intro__content--zXS24";
export var intro__content__body = "ReviewCentreIntro--intro__content__body--fFXNc";
export var intro__content__subTitle = "ReviewCentreIntro--intro__content__subTitle--SSBl8";
export var intro__content__title = "ReviewCentreIntro--intro__content__title--88pYx";
export var speech = "ReviewCentreIntro--speech--shQb9";
export var speech__bubble = "ReviewCentreIntro--speech__bubble--kYWkG";
export var speech__bubble__image = "ReviewCentreIntro--speech__bubble__image--5jBLd";
export var speech__content = "ReviewCentreIntro--speech__content--QuNZM";
export var speech__content__subTitle = "ReviewCentreIntro--speech__content__subTitle--Jh2TC";
export var speech__content__title = "ReviewCentreIntro--speech__content__title--Wc08s";
export var tkMyriadProSemiCondensed = "ReviewCentreIntro--tk-myriad-pro-semi-condensed---ZpOn";