// extracted by mini-css-extract-plugin
export var faq = "ReviewCentreOverviewFaq--faq--GHPsj";
export var faq__container = "ReviewCentreOverviewFaq--faq__container--8isWP";
export var faq__container___reverse = "ReviewCentreOverviewFaq--faq__container___reverse--cQUkn";
export var faq__content = "ReviewCentreOverviewFaq--faq__content--3WJAR";
export var faq__imageContainer = "ReviewCentreOverviewFaq--faq__imageContainer--7vnby";
export var faq__imageContainer__image = "ReviewCentreOverviewFaq--faq__imageContainer__image--rPoo4";
export var faq__quote = "ReviewCentreOverviewFaq--faq__quote--oftUw";
export var faq__quote__avatar = "ReviewCentreOverviewFaq--faq__quote__avatar--94UD2";
export var faq__quote__content = "ReviewCentreOverviewFaq--faq__quote__content--slbQl";
export var faq__quote__user = "ReviewCentreOverviewFaq--faq__quote__user--XnGXy";
export var faq__title = "ReviewCentreOverviewFaq--faq__title--uto94";
export var tkMyriadProSemiCondensed = "ReviewCentreOverviewFaq--tk-myriad-pro-semi-condensed--6WE6Q";