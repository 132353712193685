import React from 'react';
import slugify from 'react-slugify';
import Img from 'gatsby-image';

import Markdown from 'components/common/markdown/Markdown';
import Section from 'components/common/section/Section';
import { Wrapper } from 'components/common/wrapper/Wrapper';
import RatingStars from 'components/directus/rating-stars/RatingStars';
import reviewCentreOverviewFaqHook from 'hooks/review-centre-overview-faq/review-centre-overview-faq.hook';
import { initials } from 'utils/initials';

import {
  faq,
  faq__container,
  faq__container___reverse,
  faq__imageContainer,
  faq__imageContainer__image,
  faq__content,
  faq__title,
  faq__quote,
  faq__quote__avatar,
  faq__quote__content,
  faq__quote__user,
} from './ReviewCentreOverviewFaq.scss';

export default (): JSX.Element => {
  const faqs = reviewCentreOverviewFaqHook().sort();
  return (
    <>
      {faqs.map(({ node }, i: number) => (
        <Section
          className={faq}
          key={`review-centre-overview-faq-${slugify(node.title)}`}
          colour={i % 2 === 0 ? 'white' : 'grey'}
        >
          <Wrapper>
            <div className={`${faq__container} ${node.image_position === 'Right' ? faq__container___reverse : null}`}>
              <div className={faq__imageContainer}>
                <Img
                  fixed={node.image.localFile.childImageSharp.fixed}
                  alt={node.image_alt}
                  className={faq__imageContainer__image}
                  fadeIn
                />
              </div>

              <div className={faq__content}>
                <h2 className={faq__title}>
                  <Markdown source={node.title} />
                </h2>
                <p>
                  <Markdown source={node.body} />
                </p>

                <div
                  className={faq__quote}
                  style={{ backgroundColor: node.quote_background_colour, borderColor: node.quote_border_colour }}
                >
                  <div className={faq__quote__avatar} style={{ backgroundColor: node.background_colour }}>
                    <span>{initials(node.user_name)}</span>
                  </div>
                  <div>
                    <RatingStars score={5} starDimension="21px" />
                    <div className={faq__quote__content}>
                      <p>{node.user_quote}</p>
                    </div>
                    <div className={faq__quote__user}>
                      <span style={{ color: node.background_colour }}>{node.user_name}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Wrapper>
        </Section>
      ))}
    </>
  );
};
