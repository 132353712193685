import React from 'react';
import scrollTo from 'gatsby-plugin-smoothscroll';
import { navigate } from 'gatsby';

import Button from 'components/common/button/Button';
import SectionTitle from 'components/directus/section-title/SectionTitle';
import sectionTitleHook from 'hooks/section-title/section-title.hook';
import { getFieldBySlug } from 'utils/directus/get-field-by-slug/get-field-by-slug';
import { Props } from './CtaPanel.interface';

import { margin as marginClass, ctaPanel, ctaPanel__link } from './CtaPanel.scss';

export default ({ slug, margin }: Props): JSX.Element => {
  const { node } = getFieldBySlug(slug, sectionTitleHook());

  const checkLocation = () => {
    if (document !== undefined && window !== undefined && document.location !== undefined) {
      if (document.location.pathname !== '/' && document.location.pathname !== '/scottish-company-formation/') {
        navigate('/');
      }
      scrollTo('#home-top');
    }
  };

  return (
    <div className={margin ? marginClass : ''}>
      <div className={ctaPanel}>
        <SectionTitle title={node.heading} subtitle={node.subheading} align="center" />
      </div>
      <Button onClick={() => checkLocation()} role="primary" size="large" className={ctaPanel__link}>
        Register Now
      </Button>
      <br />
    </div>
  );
};
